import { Box, Button, Container, CssBaseline, Link, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useNotify, useRedirect, useTranslate } from 'react-admin';
import { useMutation } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { API_URL } from '../config/GLOBAL_VARIABLES';
import logo from '../images/Logo.svg';

const ResetPassword = () => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const [newPassword, setNewPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const notify = useNotify();
    const { id, token } = useParams();
    const { mutate, isLoading } = useMutation(
        async () => {
            const response = await fetch(`${API_URL}/auth/tokenPasswordChange`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, token, newPassword }),
            });
            if (!response.ok) {
                throw new Error('Error');
            }
            const data = await response.json();
            return data;
        },
        {
            onSuccess: ({ token }) => {
                notify(translate('myroot.forgotPassword.passwordResetSuccessMessage'), {
                    type: 'info',
                    autoHideDuration: 60000,
                });
                redirect('/login');
            },
            onError: () => {
                notify(translate('myroot.forgotPassword.passwordResedFailMessage'), {
                    type: 'error',
                    autoHideDuration: 60000,
                });
                redirect('/forgot-password');
            },
        }
    );

    const submit = async (e) => {
        e.preventDefault();
        mutate();
    };
    return (
        <>
            <Container component='main' maxWidth='sm'>
                <CssBaseline />
                <Box
                    component='form'
                    onSubmit={submit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '8rem',
                    }}>
                    <Box display='flex' alignItems='center' justifyContent='center' sx={{ marginBottom: '2rem' }}>
                        <Link color='inherit' href='https://app.tyrefleet.fi'>
                            <img src={logo} style={{ width: '250px' }} alt='TyreFleet Systems logo' />
                        </Link>
                    </Box>
                    <TextField
                        label={translate('myroot.passwordChange.newPassword')}
                        type='password'
                        required
                        fullWidth
                        value={newPassword}
                        sx={{ m: 1, minWidth: 300 }}
                        error={newPassword.length < 6 && newPassword !== ''}
                        helperText={
                            newPassword.length < 6 && newPassword !== ''
                                ? translate('myroot.passwordChange.validateLength')
                                : ''
                        }
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                        }}
                    />
                    <TextField
                        label={translate('myroot.passwordChange.passwordConfirmation')}
                        type='password'
                        required
                        fullWidth
                        value={passwordConfirmation}
                        sx={{ m: 1, minWidth: 300 }}
                        error={passwordConfirmation.length < 6 && passwordConfirmation !== ''}
                        helperText={
                            newPassword.length < 6 && newPassword !== ''
                                ? translate('myroot.passwordChange.validateLength')
                                : ''
                        }
                        onChange={(e) => {
                            setPasswordConfirmation(e.target.value);
                        }}
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='outlined'
                        style={{ marginTop: '1rem' }}
                        disabled={
                            !(
                                newPassword.length >= 6 &&
                                passwordConfirmation.length >= 6 &&
                                newPassword === passwordConfirmation &&
                                !isLoading
                            )
                        }>
                        {translate('myroot.passwordChange.title')}
                    </Button>
                    <Link to='/login' component={RouterLink} style={{ marginTop: '1rem' }}>
                        {translate('myroot.forgotPassword.backToLogin')}
                    </Link>
                </Box>
                <Typography variant='body2' align='center' style={{ marginTop: '3rem' }}>
                    © {new Date().getFullYear()} TyreFleet Systems Oy
                </Typography>
            </Container>
        </>
    );
};

export default ResetPassword;
