import { Container, Grid } from '@mui/material';
import React from 'react';
import { useAuthenticated } from 'react-admin';
import TyreConditionByCompany from '../components/charts/TyreConditionByCompany';
import TyreConditionByWarehouse from '../components/charts/TyreConditionByWarehouse';

const Dashboard = () => {
    useAuthenticated();
    return (
        <Container maxWidth='xxl' sx={{ mt: 2 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                    <TyreConditionByWarehouse />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TyreConditionByCompany />
                </Grid>
                {/* <Grid item xs={12} lg={12}>
                    <AllCompaniesData />
                </Grid> */}
            </Grid>
        </Container>
    );
};

export default Dashboard;
