import { API_URL } from './config/GLOBAL_VARIABLES.js';
const publicRoutes = ['/forgot-password', '/login'];
const authProvider = {
    login: async ({ email, password }) => {
        try {
            localStorage.removeItem('auth');
            localStorage.removeItem('permissions');
            const loginRequest = new Request(`${API_URL}/auth/login`, {
                method: 'POST',
                body: JSON.stringify({ email: email, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });
            const loginResponse = await fetch(loginRequest);
            if (loginResponse.status < 200 || loginResponse.status >= 300) {
                return Promise.reject(loginResponse.status.statusText);
            }
            const loginData = await loginResponse.json();
            const token = loginData.token;
            const identityRequest = new Request(`${API_URL}/auth/me`, {
                method: 'GET',
                headers: new Headers({ Authorization: `Bearer ${token}` }),
            });
            const identityResponse = await fetch(identityRequest);
            const identityData = await identityResponse.json();
            localStorage.setItem(
                'auth',
                JSON.stringify({
                    token,
                    id: identityData.data._id,
                    fullName: identityData.data.name,
                    email: identityData.data.email,
                    company: identityData.data.company,
                })
            );
            localStorage.setItem('permissions', identityData.data.role);
            return Promise.all([loginRequest, identityRequest]);
        } catch (error) {
            return Promise.reject();
        }
    },
    checkError: (error) => {
        try {
            const status = error.status;
            if (status === 401 || status === 403) {
                localStorage.removeItem('auth');
                return Promise.reject();
            }
            // other error code (404, 500, etc): no need to log out
            return Promise.resolve();
        } catch (e) {
            return Promise.reject();
        }
    },
    checkAuth: () => {
        try {
            if (publicRoutes.includes(window.location.pathname)) {
                return Promise.resolve();
            }
            return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject();
        } catch (error) {
            return Promise.reject();
        }
    },
    logout: () => {
        try {
            localStorage.removeItem('auth');
            localStorage.removeItem('permissions');
            return Promise.resolve();
        } catch (error) {
            return Promise.reject();
        }
    },
    getIdentity: () => {
        try {
            if (publicRoutes.includes(window.location.pathname)) {
                return Promise.resolve();
            }
            const { id, fullName, company } = JSON.parse(localStorage.getItem('auth'));
            return Promise.resolve({ id, fullName, company });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => {
        try {
            if (publicRoutes.includes(window.location.pathname)) {
                return Promise.resolve();
            }
            const role = localStorage.getItem('permissions');
            return role ? Promise.resolve(role) : Promise.reject();
        } catch (error) {
            return Promise.reject(error);
        }
    },
    updateToken: (token) => {
        try {
            const auth = JSON.parse(localStorage.getItem('auth'));
            auth.token = token;
            localStorage.setItem('auth', JSON.stringify(auth));
        } catch (error) {
            return Promise.reject(error);
        }
    },
};

export default authProvider;
