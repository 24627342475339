import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material/';
import React, { forwardRef } from 'react';
import { AppBar, Logout, ToggleThemeButton, UserMenu, useTheme, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import logo from '../../images/Logo.svg';
import logo_white from '../../images/Logo_white.svg';
import { darkTheme, lightTheme } from '../layout/themes';

const MyAccount = forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItem
            ref={ref}
            component={Link}
            // It's important to pass the props to allow MUI to manage the keyboard navigation
            {...props}
            to='/my-account'>
            <ListItemIcon>
                <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText>{translate('myroot.myAccount')}</ListItemText>
        </MenuItem>
    );
});

const CustomUserMenu = (props) => (
    <UserMenu {...props}>
        <MyAccount />
        <Logout />
    </UserMenu>
);

const CustomAppBar = (props) => {
    const [theme] = useTheme();
    const env = process.env.NODE_ENV;
    return (
        <AppBar {...props} userMenu={<CustomUserMenu />} color='inherit'>
            <Typography
                variant='h6'
                color='inherit'
                style={{ flex: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                id='react-admin-title'
            />
            <Box sx={{ display: { xs: 'none', md: 'block' }, transform: 'translateX(60%)' }}>
                {env === 'development' ? (
                    <Typography fontWeight='bold' textAlign='center' variant='h4' sx={{ display: 'inline' }}>
                        DEV ENV
                    </Typography>
                ) : (
                    <img
                        src={theme.palette?.mode === 'dark' ? logo_white : logo}
                        alt='logo'
                        style={{ maxWidth: '135px', padding: '7.5px' }}
                    />
                )}
            </Box>
            <span style={{ flex: 1 }} />
            <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} />
        </AppBar>
    );
};

export default CustomAppBar;
