import {
    CreateButton,
    Datagrid,
    DeleteButton,
    EditButton,
    EmailField,
    ExportButton,
    FilterButton,
    List,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField,
    TopToolbar,
    usePermissions,
} from 'react-admin';
import { hasSuperAdminRights, hasAdminRights } from '../../../utils/authorization';

const ListActions = () => {
    const { permissions } = usePermissions();
    return (
        <TopToolbar>
            <FilterButton />
            {hasSuperAdminRights(permissions) && <CreateButton />}
            <ExportButton />
        </TopToolbar>
    );
};

const UserFilters = () => {
    const { permissions } = usePermissions();
    return [
        ...[<SearchInput source='q' alwaysOn />],
        ...(hasSuperAdminRights(permissions)
            ? [
                  <ReferenceInput source='company' reference='companies' alwaysOn>
                      <SelectInput optionText='name' />
                  </ReferenceInput>,
              ]
            : []),
    ];
};

export const UserList = () => {
    const { permissions } = usePermissions();
    return (
        <List
            filters={UserFilters(permissions)}
            actions={<ListActions permissions={permissions} />}
            bulkActionButtons={hasAdminRights(permissions) ? undefined : false}>
            <Datagrid>
                <TextField source='role' />
                <TextField source='name' />
                <EmailField source='email' />
                {hasSuperAdminRights(permissions) && (
                    <ReferenceField reference='companies' source='company' link={false}>
                        <TextField source='name' />
                    </ReferenceField>
                )}
                {hasAdminRights(permissions) && <EditButton />}
                {hasAdminRights(permissions) && <DeleteButton />}
            </Datagrid>
        </List>
    );
};
