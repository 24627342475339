import { Box, Button, Card, CardContent, TextField, Typography } from '@mui/material/';
import { useState } from 'react';
import { useAuthenticated, useAuthProvider, useDataProvider, useNotify, useTranslate } from 'react-admin';
import { useMutation } from 'react-query';

const Configuration = () => {
    useAuthenticated();
    const notify = useNotify();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const authProvider = useAuthProvider();
    const { mutate } = useMutation(
        ['changePassword'],
        () =>
            dataProvider.changePassword({
                data: { currentPassword: currentPassword, newPassword: newPassword },
            }),
        {
            onSuccess: ({ token }) => {
                setCurrentPassword('');
                setNewPassword('');
                setPasswordConfirmation('');
                notify(translate('myroot.passwordChange.successMessage'), { type: 'success' });
                authProvider.updateToken(token);
            },
            onError: () => {
                notify(translate('myroot.passwordChange.failedMessage'), { type: 'warning' });
            },
        }
    );
    return (
        <Card style={{ marginTop: '2rem' }}>
            <CardContent sx={{ m: 1 }}>
                <Typography sx={{ m: 1 }} variant='h5' gutterBottom>
                    {translate('myroot.passwordChange.title')}
                </Typography>
                <Box>
                    <TextField
                        label={translate('myroot.passwordChange.currentPassword')}
                        type='password'
                        id='oldPassword'
                        required
                        value={currentPassword}
                        sx={{ m: 1, minWidth: 300 }}
                        error={currentPassword.length < 6 && currentPassword !== ''}
                        helperText={
                            currentPassword.length < 6 && currentPassword !== ''
                                ? translate('myroot.passwordChange.validateLength')
                                : ''
                        }
                        onChange={(e) => {
                            setCurrentPassword(e.target.value);
                        }}
                    />
                </Box>
                <Box>
                    <TextField
                        label={translate('myroot.passwordChange.newPassword')}
                        type='password'
                        required
                        value={newPassword}
                        sx={{ m: 1, minWidth: 300 }}
                        error={newPassword.length < 6 && newPassword !== ''}
                        helperText={
                            newPassword.length < 6 && newPassword !== ''
                                ? translate('myroot.passwordChange.validateLength')
                                : ''
                        }
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                        }}
                    />
                    <TextField
                        label={translate('myroot.passwordChange.passwordConfirmation')}
                        type='password'
                        required
                        value={passwordConfirmation}
                        sx={{ m: 1, minWidth: 300 }}
                        onChange={(e) => {
                            setPasswordConfirmation(e.target.value);
                        }}
                        error={passwordConfirmation.length < 6 && passwordConfirmation !== ''}
                        helperText={
                            passwordConfirmation.length < 6 && passwordConfirmation !== ''
                                ? translate('myroot.passwordChange.validateLength')
                                : ''
                        }
                    />
                </Box>
                <Box sx={{ m: 1 }}>
                    <Button
                        disabled={
                            !(
                                currentPassword.length >= 6 &&
                                newPassword.length >= 6 &&
                                passwordConfirmation.length >= 6 &&
                                newPassword === passwordConfirmation
                            )
                        }
                        variant='contained'
                        onClick={mutate}>
                        {translate('myroot.passwordChange.title')}
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Configuration;
