import { Button } from '@mui/material';
import * as React from 'react';
import {
    useRecordContext,
    useTranslate
} from 'react-admin';
import { Link } from 'react-router-dom';
import { LayersIcon } from '../../../icons';


export const AddTreadButton = ({ sx }) => {
    const record = useRecordContext();
    const translate = useTranslate();
    return (
        <Button
            sx={{ ...sx }}
            variant='text'
            color='primary'
            component={Link}
            startIcon={<LayersIcon />}
            to={{
                pathname: '/treads/create',
            }}
            state={{ record: { tyre: record.id } }}>
            {translate('ra.action.add')}{' '}
            {translate(`resources.treads.name`, {
                smart_count: 1,
            })}
        </Button>
    );
};