import { BooleanInput, NumberInput, ReferenceInput, SearchInput, SelectInput, TextInput } from 'react-admin';

export const tyreFilters = [
    <SearchInput source='q' alwaysOn />,
    // <TextInput source="_id" />,
    <TextInput source='bodyNumber' />,
    <TextInput source='manufacturer' />,
    <TextInput source='model' />,
    <NumberInput source='width_lt' />,
    <NumberInput source='width_gt' />,
    <NumberInput source='width' />,
    <NumberInput source='aspectRatio_lt' />,
    <NumberInput source='aspectRatio_gt' />,
    <NumberInput source='aspectRatio' />,
    <NumberInput source='diameter_lt' />,
    <NumberInput source='diameter_gt' />,
    <NumberInput source='diameter' />,
    <NumberInput source='totalKm_lt' />,
    <NumberInput source='totalKm_gt' />,
    <ReferenceInput source='company' reference='companies'>
        <SelectInput optionText='name' />
    </ReferenceInput>,
    <ReferenceInput source='warehouse' reference='warehouses'>
        <SelectInput
            optionText={(option) => {
                return option.name && option.name !== ''
                    ? `${option.name} | ${option.address}, ${option.city}`
                    : `${option.address}, ${option.city}`;
            }}
        />
    </ReferenceInput>,
    <BooleanInput source='onRim' />,
];
