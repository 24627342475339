import DoneIcon from '@mui/icons-material/Done';
import React from 'react';
import {
    CreateButton,
    DatagridConfigurable,
    EditButton,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    Pagination,
    ReferenceField,
    SelectColumnsButton,
    TextField,
    TopToolbar,
    usePermissions,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import { hasManagerRights } from '../../../utils/authorization';
import DateFieldWithLocale from '../../customFields/DateFieldWithLocale';
import NumberFieldWithLocale from '../../customFields/NumberFieldWithLocale';
import DeleteTyreBtn from './components/DeleteTyreBtn';
import { TyreExtend } from './components/TyreExtend';
import { tyreFilters } from './components/TyreFilters';

export const TyreList = () => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const resource = useResourceContext();
    return (
        <List
            storeKey={resource}
            resource={resource}
            filters={tyreFilters}
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
            perPage={25}
            sort={{ field: 'createdAt', order: 'DESC' }}
            actions={<Actions resource={resource} />}>
            <DatagridConfigurable
                preferenceKey={resource}
                expand={<TyreExtend permissions={permissions} />}
                rowClick='expand'
                bulkActionButtons={hasManagerRights(permissions) ? undefined : false}>
                <TextField source='bodyNumber' />
                <TextField source='manufacturer' />
                <TextField source='model' />
                <NumberFieldWithLocale source='width' />
                <NumberFieldWithLocale source='aspectRatio' />
                <NumberFieldWithLocale source='diameter' />
                <ReferenceField source='company' reference='companies' link={false}>
                    <TextField source='name' />
                </ReferenceField>
                <DateFieldWithLocale source='createdAt' />
                <NumberFieldWithLocale source='totalKm' />
                <FunctionField
                    label={translate(`resources.tyres.fields.currentTreadKm`)}
                    render={(record) => {
                        // return last element of array treads
                        const treads = record.treads;
                        if (treads && treads.length > 0) {
                            return treads[treads.length - 1].km;
                        }
                    }}
                />
                <NumberFieldWithLocale source='treadDepth' />
                <FunctionField
                    render={(record) => record.treads && record.treads.length}
                    label={translate(`resources.treads.name`, {
                        smart_count: 2,
                    })}
                />
                <ReferenceField source='warehouse' reference='warehouses' link={false}>
                    <FunctionField
                        label={translate(`resources.warehouses.name`, {
                            smart_count: 1,
                        })}
                        render={(record) => {
                            if (!record) {
                                return null;
                            }
                            return record.name ? record.name : record.address;
                        }}
                    />
                </ReferenceField>
                <FunctionField
                    source='onRim'
                    render={(record) => (record.onRim ? <DoneIcon /> : null)}
                    label={translate(`resources.tyres.fields.onRim`)}
                />
                <EditButton label='ra.action.edit' />
                <DeleteTyreBtn label='ra.action.delete' />
            </DatagridConfigurable>
        </List>
    );
};

const Actions = ({ resource }) => {
    return (
        <TopToolbar>
            <SelectColumnsButton preferenceKey={resource} />
            <FilterButton />
            <CreateButton />
            <ExportButton />
        </TopToolbar>
    );
};
