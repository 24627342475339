import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import { useQuery } from 'react-query';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { treadColors } from '../../config';
import ChartCard from './ChartCard';
import TooltipWithTotal from './TooltipWithTotal';

const TyreConditionByCompany = () => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const { isLoading, data } = useQuery({
        queryKey: ['tyreConditionStatistics', 'byCompany'],
        queryFn: () => dataProvider.getTyreConditionStatistics('byCompany'),
        select: (data) => data.data.byCompany,
    });
    if (!data) return null;
    if (data.length === 0) return null;
    return (
        <ChartCard
            title={`${translate('myroot.dashboard.tyreCondition')} ${
                data?.length > 1 ? translate('myroot.dashboard.byCompany') : ''
            }`}>
            {isLoading ? (
                <Stack direction='row' justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                    <CircularProgress />
                </Stack>
            ) : data.length > 1 ? (
                <TFBarChart data={data} />
            ) : (
                <TFPieChart data={data} />
            )}
        </ChartCard>
    );
};

export default TyreConditionByCompany;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (percent < 0.05) return null;
    return (
        <text x={x} y={y} fill='black' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const mapDataToPieChart = (data) => {
    const pieChartData = Object.entries(data[0])
        .filter(([key]) => key !== 'name')
        .map(([key, value]) => {
            return { name: key, value: value };
        });
    return pieChartData;
};
const getUniqueTreads = (data) => {
    const uniqueTreads = [];
    data?.forEach((item) => {
        Object.keys(item).forEach((key) => {
            if (key !== 'name' && !uniqueTreads.includes(key)) {
                uniqueTreads.push(key);
            }
        });
    });
    uniqueTreads.sort((a, b) => {
        return a - b;
    });
    return uniqueTreads;
};

const TFPieChart = ({ data }) => {
    const translate = useTranslate();
    const mappedData = mapDataToPieChart(data);
    const total = mappedData.reduce((acc, item) => acc + item.value, 0);
    return (
        <Grid container sx={{ height: '100%' }}>
            <Grid item xs={9}>
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            animationDuration={2200}
                            data={mapDataToPieChart(data)}
                            label={renderCustomizedLabel}
                            labelLine={false}>
                            {mappedData.map(({ name }) => (
                                <Cell
                                    name={
                                        name === '-1'
                                            ? translate('resources.tyres.invalidTyre')
                                            : `${translate('resources.treads.name', {
                                                  smart_count: 1,
                                              })} ${name}`
                                    }
                                    key={name}
                                    fill={treadColors[name]}
                                />
                            ))}
                        </Pie>
                        <Legend
                            formatter={(value) => (
                                <Box sx={{ display: 'inline' }} color='text.primary'>
                                    {value}
                                </Box>
                            )}
                        />
                        <Tooltip content={<TooltipWithTotal hideTotal={true} />} />
                    </PieChart>
                </ResponsiveContainer>
            </Grid>
            <Grid item xs={3}>
                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='flex-start'
                    spacing={1}
                    sx={{ height: '100%' }}>
                    {mappedData.map(({ name, value }) => (
                        <Typography fontSize={18} key={name}>
                            {translate('resources.treads.name', {
                                smart_count: 1,
                            })}{' '}
                            {name}: {value}
                        </Typography>
                    ))}
                    <Typography fontSize={18} fontWeight='bold'>
                        {translate('myroot.dashboard.total')}: {total}
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
};

const TFBarChart = ({ data }) => {
    const translate = useTranslate();
    return (
        <ResponsiveContainer>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey={(key) => key.name || 'Ajossa'} />
                <YAxis allowDecimals={false} />
                <Tooltip content={TooltipWithTotal} />
                <Legend
                    formatter={(value) => (
                        <Box sx={{ display: 'inline' }} color='text.primary'>
                            {value}
                        </Box>
                    )}
                />
                {data &&
                    getUniqueTreads(data).map((tread) => {
                        return (
                            <Bar
                                key={tread}
                                name={
                                    tread === '-1'
                                        ? translate('resources.tyres.invalidTyre')
                                        : `${translate('resources.treads.name', {
                                              smart_count: 1,
                                          })} ${tread}`
                                }
                                dataKey={tread}
                                stackId='a'
                                fill={treadColors[tread] ?? '#000000'}
                            />
                        );
                    })}
            </BarChart>
        </ResponsiveContainer>
    );
};
