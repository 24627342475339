import { MenuItem, Select, TextField as MuiTextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { Button, useDataProvider, useLocaleState, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { useMutation, useQuery } from 'react-query';
import { ArchiveIcon, UnarchiveIcon } from '../../../icons';

export const ArchivedButton = ({ sx }) => {
    const [id, setId] = useState('');
    const [notes, setNotes] = useState('');
    const record = useRecordContext();
    const translate = useTranslate();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [locale] = useLocaleState();
    const { data, isLoading } = useQuery(
        ['archivalReasons'],
        () =>
            dataProvider.getList('archivalReasons', {
                pagination: { page: 1, perPage: 0 },
                sort: { field: 'title', order: 'ASC' },
                filter: {},
            }),
        {
            staleTime: 1000 * 60 * 5, // 5min
        }
    );
    const { mutate, isLoading: isLoadingMutate } = useMutation(['tyres', 'archive', id], () =>
        dataProvider
            .archive('tyres', {
                id: record.id,
                data: { archivalReason: id, notes: notes },
            })
            .then(() => refresh())
    );
    return (
        <>
            {!isLoading && (
                <>
                    <Select
                        value={id}
                        onChange={(e) => {
                            setId(e.target.value);
                        }}
                        size='small'
                        sx={{ minWidth: '150px' }}>
                        {data.data.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.fullText[locale]}
                            </MenuItem>
                        ))}
                    </Select>
                    <MuiTextField
                        id='outlined-name'
                        label={translate('resources.tyres.fields.archivedNotes')}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                </>
            )}
            <Button
                sx={{ ...sx }}
                label={translate(`myroot.scrap`)}
                disabled={id === '' || isLoadingMutate}
                onClick={(e) => {
                    e.stopPropagation();
                    mutate();
                }}
                startIcon={<ArchiveIcon />}
            />
        </>
    );
};
export const UnArchivedButton = ({ sx }) => {
    const record = useRecordContext();
    const translate = useTranslate();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const limit = 1000 * 60 * 60 * 2; // 2 hours
    const archivedAt = new Date(record.archived?.archivedAt).getTime();
    const ArchivedInLast2Hours = Math.abs(archivedAt - Date.now()) < limit;
    const { mutate, isLoading } = useMutation(['tyres', 'unarchive', record.id], () =>
        dataProvider
            .unarchive('tyres', {
                id: record.id,
            })
            .then(() => refresh())
    );
    return (
        <Tooltip title={!ArchivedInLast2Hours && translate('resources.tyres.archivedOver2HoursAgo')}>
            <span>
                <Button
                    sx={{ ...sx }}
                    label={translate(`myroot.restore`)}
                    onClick={(e) => {
                        e.stopPropagation();
                        mutate();
                    }}
                    disabled={isLoading || !ArchivedInLast2Hours}
                    startIcon={<UnarchiveIcon />}
                />
            </span>
        </Tooltip>
    );
};

export const ConditionalArchiveButton = ({ sx }) => {
    const record = useRecordContext();
    if (record.archived) {
        return <UnArchivedButton sx={sx} />;
    } else {
        return <ArchivedButton sx={sx} />;
    }
};
