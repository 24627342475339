import { Edit, NumberInput, ReferenceInput, SimpleForm, TextInput } from 'react-admin';
import SelectInputDefaultValue from '../../customFields/SelectInputDefaultValue';

import {
    validateAddress,
    validateCity,
    validateCompany,
    validateCountry,
    validateMaxCapacity,
    validateName,
    validateZipcode,
} from './components/ValidateInputs';

export const WarehouseEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <TextInput source='id' disabled />
                <TextInput source='name' validate={validateName} />
                <TextInput source='country' validate={validateCountry} />
                <TextInput source='zipcode' validate={validateZipcode} />
                <TextInput source='city' validate={validateCity} />
                <TextInput source='address' validate={validateAddress} />
                <NumberInput source='maxCapacity' validate={validateMaxCapacity} />
                <ReferenceInput source='company' reference='companies' validate={validateCompany}>
                    <SelectInputDefaultValue optionText='name' />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};
