import { maxLength, maxValue, minValue, required } from 'react-admin';

const validateRegistrationNumber = [maxLength(20)];
const validateKmStart = [required(), maxValue(10000000)];
const validateKmEnd = [maxValue(10000000)];
const validateTread = [required()];
const validateAxle = [minValue(1), maxValue(50)];
const validateTreadDepth = [minValue(0), maxValue(100)];

export { validateRegistrationNumber, validateKmStart, validateKmEnd, validateTread, validateAxle, validateTreadDepth };
