import { Box } from '@material-ui/core';
import React from 'react';
import { MenuItemLink, usePermissions, useTranslate } from 'react-admin';
import { AlbumIcon, ApartmentIcon, HomeIcon, PeopleIcon, ArchiveIcon, DashboardIcon } from '../icons';
import { hasManagerRights } from '../../utils/authorization';

const Menu = ({ dense = false }) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    return (
        <Box
            sx={{
                transition: (theme) =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}>
            <MenuItemLink
                to={`/`}
                primaryText={translate('myroot.dashboard.title')}
                leftIcon={<DashboardIcon />}
                dense={dense}
            />
            <MenuItemLink
                to={`/tyres`}
                primaryText={translate(`resources.tyres.name`, {
                    smart_count: 2,
                })}
                leftIcon={<AlbumIcon />}
                dense={dense}
            />
            <MenuItemLink
                to={`/archive`}
                primaryText={translate(`myroot.scrapped`)}
                leftIcon={<ArchiveIcon />}
                dense={dense}
            />
            <MenuItemLink
                to={`/companies`}
                primaryText={translate(`resources.companies.name`, {
                    smart_count: 2,
                })}
                leftIcon={<ApartmentIcon />}
                dense={dense}
            />

            <MenuItemLink
                to={`/warehouses`}
                primaryText={translate(`resources.warehouses.name`, {
                    smart_count: 2,
                })}
                leftIcon={<HomeIcon />}
            />
            {hasManagerRights(permissions) && (
                <MenuItemLink
                    to={`/users`}
                    primaryText={translate(`resources.users.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<PeopleIcon />}
                />
            )}
        </Box>
    );
};

export default Menu;
