import Button from '@mui/material/Button';
import { stringify } from 'query-string';
import { useRecordContext, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { AlbumIcon } from '../../../icons';

export const PreFilteredTyresButton = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return (
        <Button
            color='primary'
            component={Link}
            startIcon={<AlbumIcon />}
            to={{
                pathname: '/tyres',
                search: stringify({
                    filter: JSON.stringify({ warehouse: record.id }),
                }),
            }}>
            {translate('resources.tyres.name', { smart_count: 2 })}
        </Button>
    );
};
