import { Box, Button, Container, CssBaseline, Link, TextField, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { useNotify, useTranslate, useRedirect } from 'react-admin';
import logo from '../images/Logo.svg';
import { API_URL } from '../config/GLOBAL_VARIABLES';
import { useMutation } from 'react-query';

const ForgotPassword = () => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const [email, setEmail] = useState('');
    const notify = useNotify();
    const { mutate, isLoading } = useMutation(
        async () => {
            const response = await fetch(`${API_URL}/auth/passwordReset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            if (!response.ok) {
                throw new Error('Error');
            }
            const data = await response.json();
            return data;
        },
        {
            onSuccess: () => {
                notify(translate('myroot.forgotPassword.emailSent'), { type: 'info', autoHideDuration: 60000 });
                redirect('/login');
            },
            onError: () => {
                notify(translate('myroot.forgotPassword.failMessage'), { type: 'error' });
            },
        }
    );
    const submit = async (e) => {
        e.preventDefault();
        mutate(email);
    };

    return (
        <>
            <Container component='main' maxWidth='sm'>
                <CssBaseline />
                <Box
                    component='form'
                    onSubmit={submit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '8rem',
                    }}>
                    <Box display='flex' alignItems='center' justifyContent='center' sx={{ marginBottom: '2rem' }}>
                        <Link color='inherit' href='https://app.tyrefleet.fi'>
                            <img src={logo} style={{ width: '250px' }} alt='TyreFleet Systems logo' />
                        </Link>
                    </Box>
                    <Typography variant='body1' style={{ marginTop: '2rem' }}>
                        {translate('myroot.forgotPassword.title')}
                    </Typography>
                    <TextField
                        fullWidth
                        name='email'
                        type='email'
                        label='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin='normal'
                        required
                        autoComplete='email'
                        autoFocus
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='outlined'
                        style={{ marginTop: '1rem' }}
                        disabled={isLoading}>
                        {translate('myroot.forgotPassword.buttonText')}
                    </Button>
                    <Link to='/login' component={RouterLink} style={{ marginTop: '1rem' }}>
                        {translate('myroot.forgotPassword.backToLogin')}
                    </Link>
                </Box>
                <Typography variant='body2' align='center' style={{ marginTop: '3rem' }}>
                    © {new Date().getFullYear()} TyreFleet Systems Oy
                </Typography>
            </Container>
        </>
    );
};

export default ForgotPassword;
