import { useGetIdentity, useGetList, usePermissions, useRecordContext } from 'react-admin';
import { hasAdminRights, hasSuperAdminRights } from '../../../../utils/authorization';

export const HasEditPermission = () => {
    const { permissions } = usePermissions();
    const { data } = useGetIdentity();
    const company = data?.company;
    const record = useRecordContext();
    const { data: companyList } = useGetList('companies', { filter: { subCompanies: record?.id, _id: company } });
    const isSubCompany = companyList?.length > 0;
    if (hasSuperAdminRights(permissions)) {
        return true;
    } else {
        return hasAdminRights(permissions) && (isSubCompany || company === record?.id);
    }
};
