export const treadColors = {
    '-1': '#FFC0CB',
    0: '#158466',
    1: '#B9FFB7',
    2: '#e9c46a',
    3: '#FF972F',
    4: '#FF4000',
};

export const defaultTreadColor = '#000000';
