import { Box, Button, Container, CssBaseline, TextField, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { Notification, useLogin, useNotify, useLogout } from 'react-admin';
import logo from '../images/Logo.svg';

const MyLoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const logout = useLogout();
    const notify = useNotify();
    const submit = (e) => {
        e.preventDefault();
        logout();
        login({ email, password }).catch(() => notify('Invalid email or password'));
    };

    return (
        <>
            <Container component='main' maxWidth='sm'>
                <CssBaseline />
                <Box
                    component='form'
                    onSubmit={submit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '8rem',
                    }}>
                    <Box display='flex' alignItems='center' justifyContent='center' sx={{ marginBottom: '2rem' }}>
                        <Link color='inherit' href='https://tyrefleet.fi'>
                            <img src={logo} style={{ width: '250px' }} alt='TyreFleet Syestems logo' />
                        </Link>
                    </Box>

                    <TextField
                        name='email'
                        type='email'
                        label='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin='normal'
                        required
                        autoComplete='email'
                        autoFocus
                        fullWidth
                    />
                    <TextField
                        name='password'
                        type='password'
                        label='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin='normal'
                        required
                        fullWidth
                    />
                    <Button type='submit' fullWidth variant='outlined' style={{ marginTop: '1rem' }}>
                        Sign In
                    </Button>
                    <Link to='/forgot-password' component={RouterLink} style={{ marginTop: '1rem' }}>
                        Forgot password?
                    </Link>
                </Box>
                <Typography variant='body2' align='center' style={{ marginTop: '3rem' }}>
                    © {new Date().getFullYear()} TyreFleet Systems Oy
                </Typography>
            </Container>
            <Notification />
        </>
    );
};

export default MyLoginPage;
