import { Create, PasswordInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';

import {
    validateCompany,
    validateEmail,
    validateName,
    validatePassword,
    validateRole,
} from './components/ValidateInputs';

export const UserCreate = () => (
    <Create redirect='list'>
        <SimpleForm>
            <TextInput source='name' validate={validateName} />
            <TextInput source='email' validate={validateEmail} />
            <PasswordInput source='password' validate={validatePassword} />
            <SelectInput
                source='role'
                choices={[
                    { id: 'user', name: 'User' },
                    { id: 'manager', name: 'Manager' },
                    { id: 'admin', name: 'Admin' },
                ]}
                validate={validateRole}
            />
            <ReferenceInput reference='companies' source='company'>
                <SelectInput optionText='name' validate={validateCompany} />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
