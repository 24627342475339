import * as React from 'react';
import { Datagrid, DeleteButton, EditButton, List, ReferenceField, TextField, usePermissions } from 'react-admin';
import { hasManagerRights } from '../../../utils/authorization';
import DateFieldWithLocale from '../../customFields/DateFieldWithLocale';
import NumberFieldWithLocale from '../../customFields/NumberFieldWithLocale';

export const DistanceList = () => {
    const { permissions } = usePermissions();
    return (
        <List bulkActionButtons={hasManagerRights(permissions) ? undefined : false}>
            <Datagrid>
                <TextField source='id' />
                <TextField source='registrationNumber' />
                <NumberFieldWithLocale source='kmStart' />
                <NumberFieldWithLocale source='kmEnd' />
                <NumberFieldWithLocale source='km' />
                <ReferenceField source='tread' reference='treads'>
                    <TextField source='id' />
                </ReferenceField>
                <DateFieldWithLocale source='startDate' required />
                {hasManagerRights(permissions) && <EditButton />}
                {hasManagerRights(permissions) && <DeleteButton />}
            </Datagrid>
        </List>
    );
};
