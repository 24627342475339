import { Grid, Stack } from '@mui/material';
import React from 'react';
import { BooleanInput, Create, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import SelectInputDefaultValue from '../../customFields/SelectInputDefaultValue';
import {
    validateAspectRatio,
    validateCompany,
    validateDiameter,
    validateManufacturer,
    validateModel,
    validateWidth,
} from './components/ValidateInputs';
export const TyreCreate = () => (
    <Create redirect='list'>
        <SimpleForm mode='onBlur' sx={{ maxWidth: 800 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <TextInput source='manufacturer' fullWidth validate={validateManufacturer} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput source='model' fullWidth validate={validateModel} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <NumberInput source='width' fullWidth validate={validateWidth} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <NumberInput source='aspectRatio' fullWidth validate={validateAspectRatio} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <NumberInput source='diameter' fullWidth validate={validateDiameter} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput source='bodyNumber' fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ReferenceInput source='company' reference='companies'>
                        <SelectInputDefaultValue optionText='name' fullWidth validate={validateCompany} />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ReferenceInput source='warehouse' reference='warehouses'>
                        <SelectInput
                            fullWidth
                            optionText={(option) => {
                                return option.name && option.name !== ''
                                    ? `${option.name} | ${option.address}, ${option.city}`
                                    : `${option.address}, ${option.city}`;
                            }}
                        />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <NumberInput source='price' fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Stack direction='row' alignItems='center' sx={{ height: '100%', mx: 2 }}>
                        <BooleanInput source='onRim' />
                        <BooleanInput source='noFullHistory' />
                    </Stack>
                </Grid>
            </Grid>
            {/* <TextInput source="construction" validate={validateConstruction} /> */}
        </SimpleForm>
    </Create>
);
