import { Edit, SelectInput, SimpleForm, TextInput } from 'react-admin';

import { validateEmail, validateName, validateRole } from './components/ValidateInputs';

export const UserEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source='id' disabled />
            <TextInput source='name' validate={validateName} />
            <TextInput source='email' disabled validate={validateEmail} />
            <SelectInput
                source='role'
                choices={[
                    { id: 'user', name: 'User' },
                    { id: 'manager', name: 'Manager' },
                    { id: 'admin', name: 'Admin' },
                ]}
                validate={validateRole}
            />
        </SimpleForm>
    </Edit>
);
