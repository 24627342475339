import {
    ChipField,
    CreateButton,
    Datagrid,
    ExportButton,
    List,
    ReferenceArrayField,
    SearchInput,
    SingleFieldList,
    TextField,
    TopToolbar,
    usePermissions,
} from 'react-admin';
import { hasSuperAdminRights } from '../../../utils/authorization';
import { AddSubCompanyBtn } from './components/AddSubCompanyBtn';
import { DeleteCompanyBtn } from './components/DeleteCompanyBtn';
import { EditCompanyBtn } from './components/EditCompanyBtn';
import { InviteUserButton } from './components/InviteUserButton';

export const CompanyList = () => {
    const { permissions } = usePermissions();
    return (
        <List actions={<ListActions permissions={permissions} />} filters={companyFilters}>
            <Datagrid bulkActionButtons={hasSuperAdminRights(permissions) ? undefined : false}>
                {/* <TextField source="id" /> */}
                <TextField source='name' />
                <ReferenceArrayField reference='companies' source='partners'>
                    <SingleFieldList linkType={false}>
                        <ChipField source='name' />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField reference='companies' source='subCompanies'>
                    <SingleFieldList linkType={false}>
                        <ChipField source='name' />
                    </SingleFieldList>
                </ReferenceArrayField>
                <AddSubCompanyBtn />
                <InviteUserButton />
                <EditCompanyBtn />
                <DeleteCompanyBtn />
            </Datagrid>
        </List>
    );
};

const ListActions = () => {
    const { permissions } = usePermissions();
    return (
        <TopToolbar>
            {hasSuperAdminRights(permissions) && <CreateButton />}
            <ExportButton />
        </TopToolbar>
    );
};
const companyFilters = [<SearchInput source='q' alwaysOn />];
