import englishMessages from 'ra-language-english';

const tyres = {
    name: 'Tyre |||| Tyres',
    fields: {
        bodyNumber: 'Body Number',
        manufacturer: 'Manufacturer',
        model: 'Model',
        width: 'Width',
        width_lt: 'Width <',
        width_gt: 'Width >',
        aspectRatio: 'Profile',
        aspectRatio_lt: 'Profile <',
        aspectRatio_gt: 'Profile >',
        diameter: 'Diameter',
        diameter_lt: 'Diameter <',
        diameter_gt: 'Diameter >',
        company: 'Company',
        createdAt: 'Created at',
        totalKm: 'Body km',
        totalKm_lt: 'Km <',
        totalKm_gt: 'Km >',
        treads: 'Treads',
        warehouse: 'Warehouse',
        noFullHistory: 'No Full History',
        onRim: 'On rim',
        archived: 'Scrapped',
        archivedBy: 'Scrapped by',
        archivedAt: 'Scrapped at',
        archivedReason: 'Reason for scrapping',
        archivedNotes: 'Notes',
        notes: 'Notes',
        currentTreadKm: 'Tread km',
        modified: {
            name: 'Modified',
            at: 'Date',
            by: 'By',
        },
        price: 'Body Price (€)',
        priceTab: 'Price',
        totalPrice: 'Total price (€)',
    },
    invalidTyre: 'Invalid tyre',
    noArchivedTyresTitle: 'No scrapped tyres...',
    over24hoursOldTyresCantBeDeleted: 'Tyres older than 24 hours cannot be deleted. Instead, they can be scrapped.',
    archivedOver2HoursAgo: 'Tyre scrapped over 2 hours ago cannot be restored.',
};

const customEnglishMessages = {
    ...englishMessages,
    resources: {
        tyres: tyres,
        archive: tyres,
        treads: {
            name: 'Tread |||| Treads',
            fields: {
                manufacturer: 'Manufacturer',
                model: 'Model',
                startDate: 'Start date',
                endDate: 'End date',
                tyre: 'Tyre',
                distances: 'Distances',
                startTreadDepth: 'Tread depth (mm)',
                treadPrice: 'Tread price',
            },
        },
        distances: {
            name: 'Distance |||| Distances',
            fields: {
                registrationNumber: 'Vehicle id',
                kmStart: 'Km start',
                kmEnd: 'Km end',
                tread: 'Tread',
                startDate: 'Start date',
                axle: 'Axle',
                treadUsed: 'Usage (mm)',
                startTreadDepth: 'Start tread depth (mm)',
                endTreadDepth: 'End tread depth (mm)',
            },
            locations: {
                D: 'D - Drive axle',
                F: 'F - Front axle',
                T: 'T - Trailers',
                Z: 'Z - All positions',
                B: 'B - Bogie axle',
            },
        },
        warehouses: {
            name: 'Warehouse |||| Warehouses',
            fields: {
                country: 'Country',
                zipcode: 'Zipcode',
                city: 'City',
                address: 'Address',
                capacity: 'Number of tires',
                maxCapacity: 'Capacity',
                company: 'Company',
                name: 'Name',
            },
        },
        companies: {
            name: 'Company||||Companies',
            fields: {
                name: 'Name',
                partners: 'Partners',
                subCompanies: 'Sub companies',
            },
            addSubCompany: 'Add SubCompany',
            inviteUser: 'Invite user',
        },
        users: {
            name: 'User |||| Users',
            fields: {
                role: 'Role',
                name: 'Name',
                email: 'Email',
                company: 'Company',
            },
        },
        archiveReasons: {
            name: 'Archive reason |||| Archive reasons',
            fields: {
                fullText: 'Archive reason',
            },
        },
    },
    myroot: {
        language: 'Language',
        theme: 'Theme',
        dark: 'Dark',
        light: 'Light',
        configuration: 'Configuration',
        myAccount: 'My Account',
        under: 'under',
        over: 'over',
        archive: 'Archive',
        scrap: 'Scrap',
        scrapped: 'Scrapped',
        scrapDetails: 'Scrapping details',
        restore: 'Restore',
        register: 'Register',
        invalidToken: 'Invalid token, please request new invite link from your administrator.',
        registerFromInvite: {
            invite: 'Invite',
            inviteSent: 'Invite sent',
            inviteFailed: 'Invite failed. Check email address.',
            registrationSucceed: 'Registration succeed. You can now sign in.',
            registrationFailed: 'Registration failed. Please try again.',
            duplicateEmail: 'Email already registered. Please sign in.',
            inviteUserDescription: 'Send registration link to user via email.',
        },
        passwordChange: {
            title: 'Change password',
            currentPassword: 'Current password',
            newPassword: 'New password',
            passwordConfirmation: 'Password confirmation',
            successMessage: 'Password changed successfully',
            validateLength: 'Password must be at least 6 characters long',
            failedMessage: 'Password change failed. Check your current password',
        },
        forgotPassword: {
            title: 'Forgot password?',
            emailSent:
                'If your email is in our database, you will receive an email with instructions on how to reset your password.',
            buttonText: 'Email Me A recovery Link',
            backToLogin: 'Back to login',
            failMessage: 'Password recovery failed.',
            passwordResedFailMessage: 'Password change failed. Please request new recovery link and try again.',
            passwordResetSuccessMessage: 'Password changed successfully. Please sign in with the new password.',
        },
        dashboard: {
            title: 'Dashboard',
            onTheRoad: 'On the road',
            byWarehouse: 'By warehouse',
            byCompany: 'By company',
            tyreCondition: 'Tyre condition',
            all: 'All',
            total: 'Total',
        },
    },
};

export default customEnglishMessages;
