import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Admin, CustomRoutes, fetchUtils, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import authProvider from './authProvider';
import provider from './dataProvider';

import englishMessages from './config/i18n/en';
import finnishMessages from './config/i18n/fi';

// Resources
import { CompanyCreate, CompanyEdit, CompanyList, CompanyShow } from './components/resources/Companies';
import { DistanceCreate, DistanceEdit, DistanceList } from './components/resources/Distances';
import { TreadCreate, TreadEdit, TreadList } from './components/resources/Treads/';
import { ArchiveTyreList, TyreCreate, TyreEdit, TyreList } from './components/resources/Tyres/';
import { UserCreate, UserEdit, UserList } from './components/resources/Users';
import { WarehouseCreate, WarehouseEdit, WarehouseList } from './components/resources/Warehouses';

// Pages
import Dashboard from './pages/Dashboard';
import ForgotPassword from './pages/ForgotPassword';
import InvitePage from './pages/InvitePage';
import LoginPage from './pages/LoginPage';
import MyAccount from './pages/MyAccount';
import ResetPassword from './pages/ResetPassword.js';

import CustomLayout from './components/layout/CustomLayout';

import { API_URL } from './config/GLOBAL_VARIABLES.js';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const auth = localStorage.getItem('auth');
    if (auth) {
        const { token } = JSON.parse(auth);
        options.headers.set('Authorization', `Bearer ${token}`);
    }
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = provider(API_URL, httpClient);

const translations = {
    eng: englishMessages,
    fin: finnishMessages,
};
const i18nProvider = polyglotI18nProvider(
    (locale) => translations[locale],
    'eng', // default locale
    [
        { locale: 'eng', name: 'English' },
        { locale: 'fin', name: 'Suomi' },
    ]
);

const App = () => {
    return (
        <Admin
            loginPage={LoginPage}
            dashboard={Dashboard}
            dataProvider={dataProvider}
            authProvider={authProvider}
            layout={CustomLayout}
            i18nProvider={i18nProvider}
            disableTelemetry>
            <Resource name='tyres' list={TyreList} edit={TyreEdit} create={TyreCreate} />
            <Resource name='archive' list={ArchiveTyreList} />
            <Resource name='treads' list={TreadList} edit={TreadEdit} create={TreadCreate} />
            <Resource name='distances' list={DistanceList} edit={DistanceEdit} create={DistanceCreate} />
            <Resource name='warehouses' list={WarehouseList} edit={WarehouseEdit} create={WarehouseCreate} />
            <Resource
                name='companies'
                list={CompanyList}
                create={CompanyCreate}
                edit={CompanyEdit}
                show={CompanyShow}
            />
            <Resource name='users' list={UserList} edit={UserEdit} create={UserCreate} />
            <Resource name='archivalReasons' />
            <CustomRoutes>
                <Route path='/my-account' element={<MyAccount />} />
            </CustomRoutes>
            <CustomRoutes noLayout>
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/reset-password/:id/:token' element={<ResetPassword />} />
                <Route path='/invite/:inviteToken' element={<InvitePage />} />
            </CustomRoutes>
        </Admin>
    );
};

export default App;
