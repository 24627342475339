import * as React from 'react';
import {
    Datagrid,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    ReferenceField,
    TextField,
    usePermissions,
    useTranslate,
} from 'react-admin';
import { hasManagerRights } from '../../../utils/authorization';
import DateFieldWithLocale from '../../customFields/DateFieldWithLocale';
import NumberFieldWithLocale from '../../customFields/NumberFieldWithLocale';
import { TreadExtend } from './components/TreadExtend';

export const TreadList = () => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    return (
        <List>
            <Datagrid
                expand={<TreadExtend />}
                rowClick='expand'
                bulkActionButtons={hasManagerRights(permissions) ? undefined : false}>
                <TextField source='id' />
                <TextField source='manufacturer' />
                <TextField source='model' />
                <DateFieldWithLocale source='startDate' />
                <DateFieldWithLocale source='endDate' />
                <ReferenceField source='tyre' reference='tyres'>
                    <TextField source='id' />
                </ReferenceField>
                <FunctionField
                    label={translate(`resources.treads.name`, {
                        smart_count: 2,
                    })}
                    render={(record) => record.distances !== undefined && record.distances.length}
                />
                <NumberFieldWithLocale source='km' />
                <EditButton />
                {hasManagerRights(permissions) && <DeleteButton />}
            </Datagrid>
        </List>
    );
};
