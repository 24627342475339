import { Grid } from '@mui/material';
import {
    ChipField,
    Edit,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    usePermissions,
} from 'react-admin';
import { validateName } from './components/ValidateInputs';
import { hasSuperAdminRights } from '../../../utils/authorization';

export const CompanyEdit = () => {
    const { permissions } = usePermissions();
    return (
        <Edit>
            <SimpleForm sx={{ maxWidth: 800 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextInput disabled source='id' fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput source='name' validate={validateName} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {hasSuperAdminRights(permissions) && (
                            <ReferenceArrayInput reference='companies' source='partners'>
                                <SelectArrayInput fullWidth>
                                    <ChipField source='name' />
                                </SelectArrayInput>
                            </ReferenceArrayInput>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {hasSuperAdminRights(permissions) && (
                            <ReferenceArrayInput reference='companies' source='subCompanies'>
                                <SelectArrayInput fullWidth>
                                    <ChipField source='name' />
                                </SelectArrayInput>
                            </ReferenceArrayInput>
                        )}
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};
