import React from 'react';
import { Layout } from 'react-admin';
import CustomAppBar from './CustomAppBar';
import Menu from './Menu';

const CustomLayout = (props) => {
    return <Layout {...props} sx={{ '& .RaLayout-appFrame': { marginTop: 9 } }} appBar={CustomAppBar} menu={Menu} />;
};

export default CustomLayout;
