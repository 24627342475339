import { Grid } from '@mui/material';
import * as React from 'react';
import { DateInput, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import {
    validateManufacturer,
    validateModel,
    validateStartTreadDepth,
    validateTyre,
} from './components/ValidateInputs';

export const TreadEdit = () => (
    <Edit redirect='/tyres'>
        <SimpleForm sx={{ maxWidth: 800 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <TextInput source='id' fullWidth disabled />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ReferenceInput source='tyre' reference='tyres'>
                        <SelectInput optionText='id' validate={validateTyre} fullWidth disabled />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput source='manufacturer' fullWidth validate={validateManufacturer} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput source='model' fullWidth validate={validateModel} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DateInput source='startDate' fullWidth required />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DateInput source='endDate' fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <NumberInput source='startTreadDepth' fullWidth validate={validateStartTreadDepth} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <NumberInput source='treadPrice' fullWidth />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);
