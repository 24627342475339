import { Box } from '@material-ui/core';
import * as React from 'react';
import {
    Datagrid,
    DeleteButton,
    EditButton,
    ReferenceManyField,
    SimpleShowLayout,
    TextField,
    usePermissions,
    useTranslate,
} from 'react-admin';
import { hasManagerRights } from '../../../../utils/authorization';
import DateFieldWithLocale from '../../../customFields/DateFieldWithLocale';
import NumberFieldWithLocale from '../../../customFields/NumberFieldWithLocale';
import { AddDistanceButton } from '../../Distances/components/AddDistanceButton';

export const TreadExtend = ({ tyre }) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    return (
        <Box style={{ marginLeft: '10rem' }}>
            <SimpleShowLayout syncWithLocation={false} style={{ marginLeft: '3rem' }}>
                <ReferenceManyField
                    reference='distances'
                    target='tread'
                    sort={{ field: 'startDate', order: 'ASC' }}
                    label={translate('resources.distances.name', { smart_count: 2 })}>
                    <Datagrid>
                        <TextField source='registrationNumber' />
                        <DateFieldWithLocale source='startDate' />
                        <DateFieldWithLocale source='endDate' />
                        <NumberFieldWithLocale source='km' />
                        <NumberFieldWithLocale source='treadUsed' />
                        {hasManagerRights(permissions) && !tyre.archived && <EditButton />}
                        {hasManagerRights(permissions) && !tyre.archived && <DeleteButton redirect='/tyres' />}
                    </Datagrid>
                </ReferenceManyField>
                {!tyre.archived && <AddDistanceButton />}
            </SimpleShowLayout>
        </Box>
    );
};
