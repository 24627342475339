import { Box, Button, Container, CssBaseline, Link, TextField, Typography } from '@mui/material';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNotify, useRedirect, useTranslate } from 'react-admin';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { API_URL } from '../config/GLOBAL_VARIABLES';
import logo from '../images/Logo.svg';

const InvitePage = () => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const notify = useNotify();
    const { inviteToken } = useParams();
    const [decoded, setDecoded] = useState({});
    // decode jwt token to get email
    useEffect(() => {
        try {
            const decoded = jwt_decode(inviteToken);
            setDecoded(decoded);
        } catch (err) {
            setDecoded({});
        }
    }, [inviteToken]);

    const { mutate, isLoading } = useMutation(
        async () => {
            const response = await fetch(`${API_URL}/auth/registerFromInvite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ inviteToken, password, name }),
            });
            if (!response.ok) {
                const err = await response.json();
                throw new Error(err.message);
            }
            const data = await response.json();
            return data;
        },
        {
            onSuccess: () => {
                notify(translate('myroot.registerFromInvite.registrationSucceed'), {
                    type: 'info',
                    autoHideDuration: 60000,
                });
                redirect('/login');
            },
            onError: (err) => {
                if (err.message === 'Duplicate field value entered') {
                    notify(translate('myroot.registerFromInvite.duplicateEmail'), {
                        type: 'info',
                        autoHideDuration: 60000,
                    });
                    redirect('/login');
                } else {
                    notify(err.message, {
                        type: 'error',
                        autoHideDuration: 60000,
                    });
                }
            },
        }
    );

    const submit = async (e) => {
        e.preventDefault();
        // mutate();
        mutate();
    };
    return (
        <>
            <Container component='main' maxWidth='sm'>
                <CssBaseline />
                <Box
                    component='form'
                    onSubmit={submit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '8rem',
                    }}>
                    <Box display='flex' alignItems='center' justifyContent='center' sx={{ marginBottom: '2rem' }}>
                        <Link color='inherit' href='https://app.tyrefleet.fi'>
                            <img src={logo} style={{ width: '250px' }} alt='TyreFleet Systems logo' />
                        </Link>
                    </Box>
                    {/* Check jwt expire */}
                    {!inviteToken || !decoded.email || !decoded.company || Date.now() >= decoded.exp * 1000 ? (
                        <Typography variant='h6' gutterBottom>
                            {translate('myroot.invalidToken')}
                        </Typography>
                    ) : (
                        <>
                            <TextField
                                label={translate('resources.users.fields.email')}
                                type='text'
                                fullWidth
                                value={decoded.email}
                                sx={{ m: 1, minWidth: 300 }}
                                inputProps={{ readOnly: true }}
                            />
                            <TextField
                                label={translate('resources.users.fields.name')}
                                type='text'
                                required
                                fullWidth
                                value={name}
                                sx={{ m: 1, minWidth: 300 }}
                                error={name.length === 0}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                            <TextField
                                label={translate('myroot.passwordChange.newPassword')}
                                type='password'
                                required
                                fullWidth
                                value={password}
                                sx={{ m: 1, minWidth: 300 }}
                                error={password.length < 6 && password !== ''}
                                helperText={
                                    password.length < 6 && password !== ''
                                        ? translate('myroot.passwordChange.validateLength')
                                        : ''
                                }
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                            <TextField
                                label={translate('myroot.passwordChange.passwordConfirmation')}
                                type='password'
                                required
                                fullWidth
                                value={passwordConfirmation}
                                sx={{ m: 1, minWidth: 300 }}
                                error={passwordConfirmation.length < 6 && passwordConfirmation !== ''}
                                helperText={
                                    password.length < 6 && password !== ''
                                        ? translate('myroot.passwordChange.validateLength')
                                        : ''
                                }
                                onChange={(e) => {
                                    setPasswordConfirmation(e.target.value);
                                }}
                            />
                            <Button
                                type='submit'
                                fullWidth
                                variant='outlined'
                                style={{ marginTop: '1rem' }}
                                disabled={
                                    !(
                                        password.length >= 6 &&
                                        passwordConfirmation.length >= 6 &&
                                        password === passwordConfirmation &&
                                        name.length > 0 &&
                                        !isLoading
                                    )
                                }>
                                {translate('myroot.register')}
                            </Button>
                        </>
                    )}
                </Box>
                <Typography variant='body2' align='center' style={{ marginTop: '3rem' }}>
                    © {new Date().getFullYear()} TyreFleet Systems Oy
                </Typography>
            </Container>
        </>
    );
};

export default InvitePage;
