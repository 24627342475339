import { Box, CircularProgress, MenuItem, Select, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import { useQuery } from 'react-query';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { treadColors } from '../../config';
import ChartCard from './ChartCard';
import TooltipWithTotal from './TooltipWithTotal';

const TyreConditionByWarehouse = () => {
    const translate = useTranslate();
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const dataProvider = useDataProvider();
    const { isLoading, data } = useQuery({
        queryKey: ['tyreConditionStatistics', 'byWarehouse'],
        queryFn: () => dataProvider.getTyreConditionStatistics('byWarehouse'),
        select: (data) => data.data.byWarehouse,
        onSuccess: () => {
            setSelectedWarehouse('all');
        },
    });
    if (!data) return null;
    if (data.all.length === 0) return null;

    const uniqueTreads = (data) => {
        const uniqueTreads = [];
        data?.forEach((item) => {
            Object.keys(item).forEach((key) => {
                if (key !== 'name' && !uniqueTreads.includes(key)) {
                    uniqueTreads.push(key);
                }
            });
        });
        uniqueTreads.sort((a, b) => {
            return a - b;
        });
        return uniqueTreads;
    };
    return (
        <ChartCard
            title={translate('myroot.dashboard.byWarehouse')}
            action={
                data &&
                Object.keys(data).length > 2 && (
                    <Select
                        size='small'
                        value={selectedWarehouse}
                        onChange={(e) => {
                            setSelectedWarehouse(e.target.value);
                        }}>
                        {Object.keys(data).map((key) => (
                            <MenuItem key={key} value={key}>
                                {key === 'all' ? translate('myroot.dashboard.all') : key}
                            </MenuItem>
                        ))}
                    </Select>
                )
            }>
            <ResponsiveContainer>
                {isLoading ? (
                    <Stack direction='row' justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                        <CircularProgress />
                    </Stack>
                ) : (
                    <BarChart data={data[selectedWarehouse]}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis
                            dataKey={({ name }) =>
                                name === 'onTheRoad' ? translate('myroot.dashboard.onTheRoad') : `${name}`
                            }
                        />
                        <YAxis allowDecimals={false} />
                        <Tooltip content={TooltipWithTotal} />
                        <Legend
                            formatter={(value) => (
                                <Box sx={{ display: 'inline' }} color='text.primary'>
                                    {value}
                                </Box>
                            )}
                        />
                        {data &&
                            uniqueTreads(data[selectedWarehouse]).map((tread) => {
                                return (
                                    <Bar
                                        key={tread}
                                        name={
                                            tread === '-1'
                                                ? translate('resources.tyres.invalidTyre')
                                                : `${translate('resources.treads.name', {
                                                      smart_count: 1,
                                                  })} ${tread}`
                                        }
                                        dataKey={tread}
                                        stackId='a'
                                        fill={treadColors[tread]}
                                    />
                                );
                            })}
                        <BarChart />
                    </BarChart>
                )}
            </ResponsiveContainer>
        </ChartCard>
    );
};

export default TyreConditionByWarehouse;
