import { maxLength, maxValue, minValue, regex, required } from 'react-admin';

const validateName = [maxLength(100)];
const validateCountry = [required(), maxLength(100)];
const validateZipcode = [regex(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be a valid Zip Code')];
const validateCity = [required(), maxLength(100)];
const validateAddress = [required(), maxLength(100)];
const validateMaxCapacity = [minValue(0), maxValue(100000)];
const validateCompany = [required()];

export {
    validateName,
    validateCountry,
    validateZipcode,
    validateCity,
    validateAddress,
    validateMaxCapacity,
    validateCompany,
};
