import {
    CreateButton,
    Datagrid,
    ExportButton,
    List,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField,
    TopToolbar,
    usePermissions,
} from 'react-admin';
import { hasManagerRights } from '../../../utils/authorization';
import NumberFieldWithLocale from '../../customFields/NumberFieldWithLocale';
import { DeleteWarehouseBtn } from './components/DeleteWarehouseBtn';
import { EditWarehouseBtn } from './components/EditWarehouseBtn';
import { PreFilteredTyresButton } from './components/PreFilteredTyresButton';

export const WarehouseList = () => {
    const { permissions } = usePermissions();
    return (
        <List actions={<ListActions permissions={permissions} />} filters={warehouseFilters}>
            <Datagrid bulkActionButtons={hasManagerRights(permissions) ? undefined : false}>
                {/* <TextField source="id" /> */}
                <TextField source='name' />
                <TextField source='country' />
                <TextField source='zipcode' />
                <TextField source='city' />
                <TextField source='address' />
                <NumberFieldWithLocale source='capacity' />
                <NumberFieldWithLocale source='maxCapacity' />
                <ReferenceField source='company' reference='companies' link={false}>
                    <TextField source='name' />
                </ReferenceField>
                <PreFilteredTyresButton />
                <EditWarehouseBtn />
                <DeleteWarehouseBtn />
            </Datagrid>
        </List>
    );
};

const warehouseFilters = [
    <SearchInput source='q' alwaysOn />,
    <ReferenceInput source='company' reference='companies' alwaysOn>
        <SelectInput optionText='name' />
    </ReferenceInput>,
];
const ListActions = () => {
    const { permissions } = usePermissions();
    return (
        <TopToolbar>
            {hasManagerRights(permissions) && <CreateButton />}
            <ExportButton />
        </TopToolbar>
    );
};
