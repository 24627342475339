import { Button } from '@mui/material';
import * as React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { DirectionsCarIcon } from '../../../icons';

export const AddDistanceButton = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return (
        <Button
            variant='text'
            color='primary'
            component={Link}
            startIcon={<DirectionsCarIcon />}
            to={{
                pathname: '/distances/create',
            }}
            state={{
                record: { tread: record.id },
            }}>
            {translate('ra.action.add')}{' '}
            {translate(`resources.distances.name`, {
                smart_count: 1,
            })}
        </Button>
    );
};
