import React from 'react';
import { DeleteButton, useRecordContext, useTranslate, usePermissions } from 'react-admin';
import { Tooltip } from '@mui/material';
import { hasManagerRights } from '../../../../utils/authorization';

const DeleteTyreBtn = () => {
    const record = useRecordContext();
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const limit = 1000 * 60 * 60 * 24; // 24 hours
    const createdAt = new Date(record.createdAt).getTime();
    const createdInLast24Hours = Math.abs(createdAt - Date.now()) > limit;
    if (!hasManagerRights(permissions)) {
        return null;
    }
    return (
        <Tooltip title={createdInLast24Hours && translate('resources.tyres.over24hoursOldTyresCantBeDeleted')}>
            <span>
                <DeleteButton label='ra.action.delete' disabled={createdInLast24Hours} />
            </span>
        </Tooltip>
    );
};

export default DeleteTyreBtn;
