import { DateField, useLocaleState } from 'react-admin';

const DateFieldWithLocale = (props) => {
    const languages = {
        eng: 'en',
        fin: 'fin',
    };
    const [locale] = useLocaleState();
    const lang = languages[locale] || 'en';
    return <DateField {...props} locales={lang} />;
};

export default DateFieldWithLocale;
