import { Grid } from '@mui/material';
import {
    DateInput,
    Edit,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    useTranslate,
} from 'react-admin';

import {
    validateAxle,
    validateKmEnd,
    validateKmStart,
    validateRegistrationNumber,
    validateTread,
    validateTreadDepth,
} from './components/ValidateInputs';

export const DistanceEdit = () => {
    const translate = useTranslate();
    return (
        <Edit redirect='/tyres'>
            <SimpleForm sx={{ maxWidth: 800 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <TextInput source='id' disabled fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput source='registrationNumber' validate={validateRegistrationNumber} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source='tread' reference='treads'>
                            <SelectInput optionText='id' validate={validateTread} fullWidth disabled />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumberInput source='kmStart' validate={validateKmStart} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumberInput source='kmEnd' validate={validateKmEnd} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DateInput source='startDate' required fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DateInput source='endDate' fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumberInput source='startTreadDepth' validate={validateTreadDepth} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumberInput source='endTreadDepth' validate={validateTreadDepth} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectInput
                            source='location'
                            required
                            fullWidth
                            choices={[
                                { id: 'D', name: translate('resources.distances.locations.D') },
                                { id: 'F', name: translate('resources.distances.locations.F') },
                                { id: 'T', name: translate('resources.distances.locations.T') },
                                { id: 'Z', name: translate('resources.distances.locations.Z') },
                                { id: 'B', name: translate('resources.distances.locations.B') },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumberInput source='axle' validate={validateAxle} required fullWidth />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};
