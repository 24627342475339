// In theme.js
import { cyan } from '@mui/material/colors';
import { defaultTheme } from 'react-admin';

// const paperColor = '#19242F';
// const bgColor = '#151e27';
export const darkTheme = {
    palette: {
        mode: 'dark',
        primary: cyan,
        // background: {
        //     default: bgColor,
        //     paper: paperColor,
        // },
    },
    components: {
        ...defaultTheme.components,
    },
};

export const lightTheme = {
    palette: {
        mode: 'light',
    },
    components: {
        ...defaultTheme.components,
    },
};
