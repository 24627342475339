import { Stack, TextareaAutosize } from '@mui/material';
import React from 'react';
import {
    Datagrid,
    DeleteButton,
    EditButton,
    Labeled,
    ReferenceField,
    ReferenceManyField,
    Tab,
    TabbedShowLayout,
    TextField,
    useLocaleState,
    usePermissions,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { hasManagerRights } from '../../../../utils/authorization';
import DateFieldWithLocale from '../../../customFields/DateFieldWithLocale';
import NumberFieldWithLocale from '../../../customFields/NumberFieldWithLocale';
import { AddTreadButton } from '../../Treads/components/AddTreadButton';
import { TreadExtend } from '../../Treads/components/TreadExtend';
import { ConditionalArchiveButton } from './ArchiveButtons';

export const TyreExtend = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    const [locale] = useLocaleState();
    const { permissions } = usePermissions();
    return (
        <TabbedShowLayout syncWithLocation={false} style={{ marginLeft: '7rem' }}>
            <Tab
                label={translate(`resources.treads.name`, {
                    smart_count: 2,
                })}>
                <ReferenceManyField
                    reference='treads'
                    target='tyre'
                    label={''}
                    sort={{ field: 'startDate', order: 'ASC' }}>
                    <Datagrid expand={<TreadExtend permissions={permissions} tyre={record} />} rowClick='expand'>
                        <TextField source='manufacturer' />
                        <TextField source='model' />
                        <DateFieldWithLocale source='startDate' />
                        <DateFieldWithLocale source='endDate' />
                        <NumberFieldWithLocale source='km' />
                        <NumberFieldWithLocale source='treadPrice' />
                        {!record.archived && <EditButton />}
                        {hasManagerRights(permissions) && !record.archived && <DeleteButton redirect='/tyres' />}
                    </Datagrid>
                </ReferenceManyField>
                {!record.archived && <AddTreadButton sx={{ width: '150px' }} />}
            </Tab>
            {record.warehouse && (
                <Tab
                    label={translate(`resources.warehouses.name`, {
                        smart_count: 1,
                    })}>
                    <Stack direction='row' spacing={6}>
                        <Labeled>
                            <ReferenceField
                                source='warehouse'
                                reference='warehouses'
                                link={false}
                                label={translate(`resources.warehouses.fields.name`)}>
                                <TextField source='name' />
                            </ReferenceField>
                        </Labeled>
                        <Labeled>
                            <ReferenceField
                                source='warehouse'
                                reference='warehouses'
                                link={false}
                                label={translate(`resources.warehouses.fields.country`)}>
                                <TextField source='country' />
                            </ReferenceField>
                        </Labeled>
                        <Labeled>
                            <ReferenceField
                                source='warehouse'
                                reference='warehouses'
                                link={false}
                                label={translate(`resources.warehouses.fields.zipcode`)}>
                                <NumberFieldWithLocale source='zipcode' />
                            </ReferenceField>
                        </Labeled>
                        <Labeled>
                            <ReferenceField
                                source='warehouse'
                                reference='warehouses'
                                link={false}
                                label={translate(`resources.warehouses.fields.city`)}>
                                <TextField source='city' />
                            </ReferenceField>
                        </Labeled>
                        <Labeled>
                            <ReferenceField
                                source='warehouse'
                                reference='warehouses'
                                link={false}
                                label={translate(`resources.warehouses.fields.address`)}>
                                <TextField source='address' />
                            </ReferenceField>
                        </Labeled>
                    </Stack>
                </Tab>
            )}
            <Tab label={translate(`myroot.scrapDetails`)}>
                <Stack direction='row' spacing={8}>
                    {record.archived && (
                        <>
                            <Labeled>
                                <ReferenceField
                                    source='archived.archiveReason'
                                    reference='archivalReasons'
                                    link={false}
                                    label={translate(`resources.tyres.fields.archivedReason`)}>
                                    <TextField source={`fullText.${locale}`} />
                                </ReferenceField>
                            </Labeled>
                            <Labeled>
                                <DateFieldWithLocale
                                    showTime
                                    source='archived.archivedAt'
                                    label={translate(`resources.tyres.fields.archivedAt`)}
                                />
                            </Labeled>
                            <Labeled>
                                <ReferenceField
                                    source='archived.archivedBy'
                                    reference='users'
                                    link={false}
                                    label={translate(`resources.tyres.fields.archivedBy`)}>
                                    <TextField source='name' />
                                </ReferenceField>
                            </Labeled>
                            {record.archived.notes && (
                                <Labeled>
                                    <TextField
                                        source='archived.notes'
                                        label={translate(`resources.tyres.fields.archivedNotes`)}
                                    />
                                </Labeled>
                            )}
                        </>
                    )}
                    <ConditionalArchiveButton sx={{ width: '150px' }} />
                </Stack>
            </Tab>
            {record.notes && (
                <Tab label={translate(`resources.tyres.fields.notes`)}>
                    <TextareaAutosize
                        disabled
                        style={{ width: '800px', borderRadius: '5px', background: 'white', padding: '10px' }}
                        value={record.notes}
                    />
                </Tab>
            )}
            {record.modified && (
                <Tab label={translate(`resources.tyres.fields.modified.name`)}>
                    <Stack direction='row' spacing={8}>
                        <Labeled>
                            <DateFieldWithLocale source='modified.at' showTime />
                        </Labeled>
                        <Labeled>
                            <ReferenceField source='modified.by' reference='users' link={false}>
                                <TextField source='name' />
                            </ReferenceField>
                        </Labeled>
                    </Stack>
                </Tab>
            )}
            {(record.price || record.totalPrice) && (
                <Tab label={translate(`resources.tyres.fields.priceTab`)}>
                    <Stack direction='row' spacing={8}>
                        <Labeled>
                            <NumberFieldWithLocale source='price' />
                        </Labeled>
                        <Labeled>
                            <NumberFieldWithLocale source='totalPrice' />
                        </Labeled>
                    </Stack>
                </Tab>
            )}
        </TabbedShowLayout>
    );
};
