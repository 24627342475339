const hasManagerRights = (permissions) => {
    return ['superadmin', 'admin', 'manager'].includes(permissions);
};

const hasAdminRights = (permissions) => {
    return ['superadmin', 'admin'].includes(permissions);
};

const hasSuperAdminRights = (permissions) => {
    return ['superadmin'].includes(permissions);
};

export { hasManagerRights, hasAdminRights, hasSuperAdminRights };
