import React from 'react';
import { Card, CardHeader, CardContent, Box } from '@mui/material';
import { useTranslate } from 'react-admin';

const TooltipWithTotal = ({ active, payload, label, hideTotal }) => {
    const translate = useTranslate();
    if (active && payload && payload.length) {
        const total = payload.reduce((acc, item) => acc + item.value, 0);
        return (
            <Card>
                <CardHeader title={label} sx={{ pb: 0 }} />
                <CardContent sx={{ mt: 0 }}>
                    {payload.map((item) => (
                        <Box key={item.dataKey}>{`${item.name}: ${item.value}`}</Box>
                    ))}
                    {!hideTotal && (
                        <Box sx={{ fontWeight: 'bold' }}>{`${translate('myroot.dashboard.total')}: ${total}`}</Box>
                    )}
                </CardContent>
            </Card>
        );
    }
    return null;
};

export default TooltipWithTotal;
