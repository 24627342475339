import { useGetIdentity, useGetList, usePermissions, useRecordContext } from 'react-admin';
import { hasManagerRights, hasSuperAdminRights } from '../../../../utils/authorization';

export const IsOwnOrSubCompany = () => {
    const { permissions } = usePermissions();
    const record = useRecordContext();
    const warehouseOwnerCompanyId = record?.company;

    const { data } = useGetIdentity();
    const userCompanyId = data?.company;

    const { data: companyList } = useGetList('companies', {
        filter: { subCompanies: warehouseOwnerCompanyId, _id: userCompanyId },
    });
    const isSubCompany = companyList?.length > 0;
    const isOwnCompany = userCompanyId === warehouseOwnerCompanyId;
    if (hasSuperAdminRights(permissions)) {
        return true;
    } else {
        return hasManagerRights(permissions) && (isSubCompany || isOwnCompany);
    }
};
