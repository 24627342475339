import {
    maxLength,
    maxValue,
    minValue, required
} from 'react-admin';

const validateManufacturer = [required(), maxLength(100)];
const validateModel = [required(), maxLength(100)];
const validateStartTreadDepth = [minValue(0), maxValue(100)];
const validateTyre = [required()];

export { validateManufacturer, validateModel, validateStartTreadDepth, validateTyre };
