import { Dialog, DialogActions, DialogContent, DialogTitle, TextField as MuiTextField } from '@mui/material';
import { useState } from 'react';
import { Button, useDataProvider, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { useMutation } from 'react-query';

import { useGetIdentity, usePermissions } from 'react-admin';
import { hasManagerRights, hasSuperAdminRights } from '../../../../utils/authorization';

export const IsManagerIsOwnCompany = () => {
    const { permissions } = usePermissions();
    const { data } = useGetIdentity();
    const company = data?.company;
    const record = useRecordContext();
    if (hasSuperAdminRights(permissions)) {
        return true;
    } else {
        return hasManagerRights(permissions) && company === record.id;
    }
};

export const AddSubCompanyBtn = () => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const refresh = useRefresh();
    const { mutate } = useMutation(['companies', 'addSubCompany', record.id, name], () =>
        dataProvider
            .addSubCompany('companies', {
                id: record.id,
                data: { name },
            })
            .then(() => refresh())
    );
    const submitHandler = () => {
        if (name !== '') {
            setOpen(false);
            setName('');
            mutate();
        }
    };
    return (
        IsManagerIsOwnCompany() && (
            <>
                <Button label={translate('resources.companies.addSubCompany')} onClick={() => setOpen(true)} />
                <Dialog open={open}>
                    <DialogTitle>{translate('resources.companies.addSubCompany')}</DialogTitle>
                    <DialogContent>
                        <MuiTextField
                            id='name'
                            label={translate('resources.companies.fields.name')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            label={translate('ra.action.cancel')}
                            color='secondary'
                            size='small'
                            onClick={() => {
                                setOpen(false);
                                setName('');
                            }}
                        />
                        <Button label='Add' size='small' disabled={name === ''} onClick={() => submitHandler()} />
                    </DialogActions>
                </Dialog>
            </>
        )
    );
};
