import finnishMessages from 'ra-language-finnish';
finnishMessages.ra.configurable = {
    customize: 'Kustoimoi',
};

const tyres = {
    name: 'Rengas |||| Renkaat',
    fields: {
        bodyNumber: 'Runkonumero',
        manufacturer: 'Valmistaja',
        model: 'Malli',
        width: 'Leveys',
        width_lt: 'Leveys <',
        width_gt: 'Leveys >',
        aspectRatio: 'Profiili',
        aspectRatio_lt: 'Profiili <',
        aspectRatio_gt: 'Profiili >',
        diameter: 'Halkaisija',
        diameter_lt: 'Halkaisija <',
        diameter_gt: 'Halkaisija >',
        company: 'Yritys',
        createdAt: 'Lisäys pvm',
        totalKm: 'Rungon km',
        totalKm_lt: 'Km <',
        totalKm_gt: 'Km >',
        treads: 'Pintoja',
        treadDepth: 'Urasyvyys (mm)',
        warehouse: 'Varasto',
        noFullHistory: 'Puutteellinen data',
        onRim: 'Vanteella',
        archived: 'Romutettu',
        archivedBy: 'Romuttaja',
        archivedAt: 'Romutus pvm',
        archivedReason: 'Romutuksen syy',
        archivedNotes: 'Huomiot',
        notes: 'Muistiinpanot',
        currentTreadKm: 'Pinnan km',
        modified: {
            name: 'Muokattu',
            at: 'Pvm',
            by: 'Muokkaaja',
        },
        price: 'Rungon Hinta (€)',
        priceTab: 'Hinta',
        totalPrice: 'Hinta yhteensä (€)',
    },
    invalidTyre: 'Virheellinen rengas',
    noArchivedTyresTitle: 'Ei romutettuja rengaita...',
    over24hoursOldTyresCantBeDeleted: 'Yli 24h vanhoja renkaita ei voi poistaa. Sen sijaan voit romuttaa ne.',
    archivedOver2HoursAgo: 'Yli 2 tuntia sitten romutettua rengasta ei voi palauttaa.',
};

const customFinnishMessages = {
    ...finnishMessages,
    resources: {
        tyres: tyres,
        archive: tyres,
        treads: {
            name: 'Pinta |||| Pinnat',
            fields: {
                manufacturer: 'Valmistaja',
                model: 'Malli',
                startDate: 'Aloitus pvm',
                endDate: 'Lopetus pvm',
                tyre: 'Rengas',
                distances: 'Etäisyydet',
                startTreadDepth: 'Urasyvyys(mm)',
                treadPrice: 'Pinnan hinta(€)',
            },
        },
        distances: {
            name: 'Etäisyys |||| Etäisyydet',
            fields: {
                registrationNumber: 'Autotunniste',
                kmStart: 'Km alussa',
                kmEnd: 'Km lopussa',
                tread: 'Pinta',
                startDate: 'Aloitus pvm',
                endDate: 'Lopetus pvm',
                axle: 'Akseli',
                location: 'Sijainti',
                treadUsed: 'Kulutus (mm)',
                startTreadDepth: 'Kulutuspinta alussa (mm)',
                endTreadDepth: 'Kulutuspinta lopussa (mm)',
            },
            locations: {
                D: 'D - Vetoakseli',
                F: 'F - Etuakseli',
                T: 'T - Perävaunu',
                Z: 'Z - Kaikki akselit',
                B: 'B - Teliakseli',
            },
        },
        warehouses: {
            name: 'Varasto |||| Varastot',
            fields: {
                country: 'Maa',
                zipcode: 'Postinumero',
                city: 'Kaupunki',
                address: 'Osoite',
                maxCapacity: 'Kapasiteetti',
                capacity: 'Rengas lukumäärä',
                company: 'Yritys',
                name: 'Nimi',
            },
        },
        companies: {
            name: 'Yritys||||Yritykset',
            fields: {
                name: 'Nimi',
                partners: 'Kumppaniyritykset',
                subCompanies: 'Pienyritykset',
            },
            addSubCompany: 'Lisää pienyritys',
            inviteUser: 'Kutsu käyttäjä',
        },
        users: {
            name: 'Käyttäjä |||| Käyttäjät',
            fields: {
                role: 'Rooli',
                name: 'Nimi',
                email: 'Sähköposti',
                company: 'Yritys',
            },
        },
        archiveReasons: {
            name: 'Arkistointi syy |||| Arkistointi syyt',
            fields: {
                fullText: 'Arkistointi syy',
            },
        },
    },
    myroot: {
        language: 'Kieli',
        theme: 'Teema',
        dark: 'Tumma',
        light: 'Vaalea',
        configuration: 'Konfiguraatio',
        myAccount: 'Omat tiedot',
        under: 'alle',
        over: 'yli',
        archive: 'Arkisto',
        restore: 'Palauta',
        scrap: 'Romuta',
        scrapped: 'Romutetut',
        scrapDetails: 'Romutuksen tiedot',
        register: 'Rekisteröidy',
        invalidToken: 'Virheellinen linkki, pyydä uusi kutsulinkki.',
        registerFromInvite: {
            invite: 'Kutsu',
            inviteSent: 'Kutsu lähetetty',
            inviteFailed: 'Kutsun lähettäminen epäonnistui. Tarkista sähköpostiosoite.',
            registrationSucceed: 'Rekisteröityminen onnistui. Voit nyt kirjautua sisään.',
            registrationFailed: 'Rekisteröityminen epäonnistui.',
            duplicateEmail: 'Sähköpostiosoite on jo käytössä.',
            inviteUserDescription: 'Lähettää rekiröintilinkin käyttäjälle sähköpostilla.',
        },
        passwordChange: {
            title: 'Vaihda salasana',
            currentPassword: 'Nykyinen salasana',
            newPassword: 'Uusi salasana',
            passwordConfirmation: 'Salasanan vahvistus',
            successMessage: 'Salasana vaihdettu',
            validateLength: 'Salasanan tulee olla vähintään 6 merkkiä pitkä',
            failedMessage: 'Salasanan vaihto epäonnistui. Tarkista nykyinen salasana.',
        },
        forgotPassword: {
            title: 'Unohditko salasanasi?',
            emailSent:
                'Jos sähköpostiosoitteeseesi on rekisteröity käyttäjä, lähetimme sinulle sähköpostin salasanan palauttamiseksi.',
            buttonText: 'Lähetä palautuslinkki sähköpostiini',
            backToLogin: 'Takaisin kirjautumiseen',
            failMessage: 'Salasanan palautus epäonnistui.',
            passwordResedFailMessage: 'Salasanan palautus epäonnistui. Pyydä uusi palautuslinkki ja yritä uudelleen.',
            passwordResetSuccessMessage: 'Salasana vaihdettu. Kirjaudu sisään uudella salasanalla.',
        },
        dashboard: {
            title: 'Hallintapaneeli',
            onTheRoad: 'Ajossa',
            byWarehouse: 'Varaston mukaan',
            tyreCondition: 'Renkaiden kunto',
            byCompany: 'yrityksen mukaan',
            warehouseHistory: 'Varaston historia',
            all: 'Kaikki',
            total: 'Yhteensä',
        },
    },
};

export default customFinnishMessages;
