import { DeleteButton } from 'react-admin';
import { useGetIdentity, useGetList, usePermissions, useRecordContext } from 'react-admin';
import { hasAdminRights } from '../../../../utils/authorization';
import { hasSuperAdminRights } from '../../../../utils/authorization';

const HasDeletePermission = () => {
    const { permissions } = usePermissions();
    const { data } = useGetIdentity();
    const company = data?.company;
    const record = useRecordContext();
    const { data: companyList } = useGetList('companies', { filter: { subCompanies: record?.id, _id: company } });
    const isSubCompany = companyList?.length > 0;
    if (hasSuperAdminRights(permissions)) {
        return true;
    } else {
        return hasAdminRights(permissions) && isSubCompany;
    }
};

export const DeleteCompanyBtn = () => {
    return HasDeletePermission() && <DeleteButton resource='companies' />;
};
