import { stringify } from 'query-string';

const dataProvider = (apiUrl, httpClient) => ({
    getList: (resource, params, ...rest) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        let query = {
            sort: (order === 'DESC' ? '-' : '') + field,
            limit: perPage,
            page,
        };
        if (resource === 'archive') {
            resource = 'tyres';
            params.filter = { ...params.filter, archived: true };
        }
        for (let [key, value] of Object.entries(params.filter)) {
            key = key.replace(/(_gt|_gte|_lt|_lte|_in|_ne|_nin)/g, (match) => `[${match.slice(1)}]`);
            query[key] = value;
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ headers, json }) => {
            return {
                data: json.data.map((item) => ({ id: item._id, ...item })),
                total: parseInt(headers.get('content-range')?.split('/').pop(), 10),
            };
        });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
            return {
                data: { ...json.data, id: json.data._id },
            };
        }),

    getMany: (resource, params) => {
        return Promise.allSettled(params.ids.map((id) => httpClient(`${apiUrl}/${resource}/${id}`))).then(
            (responses) => {
                return {
                    data: responses
                        .filter((item) => item.status === 'fulfilled')
                        .map((item) => {
                            return {
                                ...item?.value.json.data,
                                id: item?.value.json.data._id,
                            };
                        }),
                };
            }
        );
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        let query = {
            sort: (order === 'DESC' ? '-' : '') + field,
            limit: perPage,
            page,
        };
        if (params.target) {
            query = { ...query, [params.target]: params.id };
        }
        if (params.filter) {
            for (let [key, value] of Object.entries(params.filter)) {
                key = key.replace(/(_gt|_gte|_lt|_lte|_in|_ne|_nin)/g, (match) => `[${match.slice(1)}]`);
                query[key] = value;
            }
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ headers, json }) => {
            return {
                data: json.data.map((resource) => ({ ...resource, id: resource._id })),
                total: parseInt(headers.get('content-range').split('/').pop(), 10),
            };
        });
    },

    update: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: { id: json.data_id, ...json.data } }));
    },

    archive: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}/archive`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: { id: json.data_id, ...json.data } }));
    },
    unarchive: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}/unarchive`, {
            method: 'PUT',
        }).then(({ json }) => ({ data: { id: json.data_id, ...json.data } }));
    },
    changePassword: (params) => {
        return httpClient(`${apiUrl}/auth/changePassword`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json);
    },
    addSubCompany: (resource, params) => {
        return httpClient(`${apiUrl}/companies/${params.id}/subcompany`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json);
    },
    inviteUser: (resource, params) => {
        return httpClient(`${apiUrl}/companies/${params.id}/invite`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json);
    },
    getTyreConditionStatistics: (select) => {
        if (!select) {
            return httpClient(`${apiUrl}/warehouses/statistics/tyreCondition`, {
                method: 'GET',
            }).then(({ json }) => json);
        } else {
            return httpClient(`${apiUrl}/warehouses/statistics/tyreCondition?select=${select}`, {
                method: 'GET',
            }).then(({ json }) => json);
        }
    },

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) => {
        return Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
    },

    create: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            return {
                data: { ...params.data, id: json.data._id },
            };
        });
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({
            data: {
                ...json.data,
                id: json.data_id,
            },
        })),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(() => ({
            data: params.ids,
        })),
});

export default dataProvider;
