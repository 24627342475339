import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField as MuiTextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Button, useDataProvider, useNotify, useRecordContext, useTranslate } from 'react-admin';
import { useMutation } from 'react-query';
import { HasEditPermission } from './HasEditPermission';

export const InviteUserButton = () => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const notify = useNotify();
    const { mutate, isLoading } = useMutation(
        async () => {
            const data = await dataProvider.inviteUser('companies', {
                id: record.id,
                data: { email },
            });
            return data;
        },
        {
            onSuccess: () => {
                notify('myroot.registerFromInvite.inviteSent', {
                    type: 'info',
                });
                setOpen(false);
                setEmail('');
            },
            onError: () => {
                notify('myroot.registerFromInvite.inviteFailed', {
                    type: 'error',
                });
            },
        }
    );
    const submitHandler = () => {
        if (email !== '') {
            mutate();
        }
    };
    return (
        HasEditPermission() && (
            <>
                <Button label={translate('resources.companies.inviteUser')} onClick={() => setOpen(true)} />
                <Dialog open={open}>
                    <DialogTitle>
                        {translate('resources.companies.inviteUser')}
                        <Typography variant='subtitle1' color='textSecondary'>
                            {translate('myroot.registerFromInvite.inviteUserDescription')}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <MuiTextField
                            fullWidth
                            id='name'
                            label={translate('resources.users.fields.email')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            label={translate('ra.action.cancel')}
                            color='secondary'
                            size='small'
                            onClick={() => {
                                setOpen(false);
                                setEmail('');
                            }}
                        />
                        <Button
                            label={translate('myroot.registerFromInvite.invite')}
                            size='small'
                            disabled={email === '' || isLoading}
                            onClick={() => submitHandler()}
                        />
                    </DialogActions>
                </Dialog>
            </>
        )
    );
};
