import DoneIcon from '@mui/icons-material/Done';
import { Box, Typography } from '@mui/material';
import React from 'react';
import {
    DatagridConfigurable,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    Pagination,
    ReferenceField,
    SelectColumnsButton,
    TextField,
    TopToolbar,
    usePermissions,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import DateFieldWithLocale from '../../customFields/DateFieldWithLocale';
import NumberFieldWithLocale from '../../customFields/NumberFieldWithLocale';
import { UnArchivedButton } from './components/ArchiveButtons';
import { TyreExtend } from './components/TyreExtend';
import { tyreFilters } from './components/TyreFilters';

export const ArchiveTyreList = () => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const resource = useResourceContext();
    return (
        <List
            empty={<EmptyArchivePage />}
            actions={<Actions resource={resource} />}
            title={translate(`myroot.archive`)}
            storeKey={resource}
            resource={resource}
            filters={tyreFilters}
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
            perPage={25}
            sort={{ field: 'archived.archivedAt', order: 'DESC' }}>
            <DatagridConfigurable
                preferenceKey={resource}
                expand={<TyreExtend permissions={permissions} />}
                rowClick='expand'
                bulkActionButtons={false}>
                <TextField source='bodyNumber' />
                <TextField source='manufacturer' />
                <TextField source='model' />
                <NumberFieldWithLocale source='width' />
                <NumberFieldWithLocale source='aspectRatio' />
                <NumberFieldWithLocale source='diameter' />
                <ReferenceField source='company' reference='companies' link={false}>
                    <TextField source='name' />
                </ReferenceField>
                <DateFieldWithLocale source='createdAt' />
                <DateFieldWithLocale source='archived.archivedAt' label='resources.tyres.fields.archivedAt' />
                <NumberFieldWithLocale source='totalKm' />
                <FunctionField
                    label={translate(`resources.tyres.fields.currentTreadKm`)}
                    render={(record) => {
                        // return last element of array treads
                        const treads = record.treads;
                        if (treads && treads.length > 0) {
                            return treads[treads.length - 1].km;
                        }
                    }}
                />
                <NumberFieldWithLocale source='treadDepth' />
                <FunctionField
                    render={(record) => record.treads && record.treads.length}
                    label={translate(`resources.treads.name`, {
                        smart_count: 2,
                    })}
                />
                <ReferenceField source='warehouse' reference='warehouses' link={false}>
                    <FunctionField
                        label={translate(`resources.warehouses.name`, {
                            smart_count: 1,
                        })}
                        render={(record) => {
                            if (!record) {
                                return null;
                            }
                            return record.name ? record.name : record.address;
                        }}
                    />
                </ReferenceField>
                <FunctionField
                    source='onRim'
                    render={(record) => (record.onRim ? <DoneIcon /> : null)}
                    label={translate(`resources.tyres.fields.onRim`)}
                />
                <UnArchivedButton label='myroot.restore' />
            </DatagridConfigurable>
        </List>
    );
};

const EmptyArchivePage = () => {
    const translate = useTranslate();
    return (
        <Box textAlign='center' m={1}>
            <Typography variant='h4' paragraph>
                {translate(`resources.tyres.noArchivedTyresTitle`)}
            </Typography>
        </Box>
    );
};

const Actions = ({ resource }) => (
    <TopToolbar>
        <SelectColumnsButton preferenceKey={resource} />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);
