import { Create, SimpleForm, TextInput } from 'react-admin';
import { validateName } from './components/ValidateInputs';

export const CompanyCreate = () => {
    return (
        <Create title='Create a Company'>
            <SimpleForm redirect='list'>
                <TextInput source='name' validate={validateName} />
            </SimpleForm>
        </Create>
    );
};
