import { Box, Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';

const ChartCard = ({ children, title, height = 350, action }) => {
    return (
        <Card>
            <CardHeader title={title} action={action} />
            <CardContent>
                <Box sx={{ width: '100%', height }}>{children}</Box>
            </CardContent>
        </Card>
    );
};

export default ChartCard;
